<template>
  <div class="page-item-wrap sharingImg-wrap">
    <div id="sharingImg-con">
      <img
        src="~assets/image/main/PromotionStore.png"
        id="Promotions"
        usemap="#Promotions"
        alt="공유하기"
        style="width: 100%; height: auto; max-width: 800px"
      />
      <map name="Promotions">
        <area
          alt="AppSotre"
          title="AppSotre"
          shape="rect"
          coords="210,2721,666,2857"
          style="outline: none; cursor: pointer"
          href="https://c11.kr/zrj4"
          target="_blank"
        />
        <area
          alt="PlaySotre"
          title="PlaySotre"
          shape="rect"
          coords="722,2725,1178,2861"
          style="outline: none"
          href="https://han.gl/SeQKO"
          target="_blank"
        />
        <area
          alt="AppSharing"
          title="AppSharing"
          shape="rect"
          coords="464,2927,916,3073"
          style="outline: none"
          href="https://www.hogoo153.com/Promotion"
          target="_blank"
        />
      </map>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data: () => ({}),
  methods: {},
  async created() {
    this.$nextTick(() => {
      window.$('img[usemap]').rwdImageMaps()
    })
  },
}
</script>

<style>
.sharingImg-wrap {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.sharingImg-wrap #sharingImg-con img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}
.sharingImg-wrap #sharingImg-con area {
  cursor: pointer;
}
.page-item-wrap {
  background-color: transparent;
}
</style>
